$idsk-assets-path: "/src/assets/";
$govuk-assets-path: "/src/assets/";

$govuk-global-styles: true;
@import 'normalize.css';
@import '@id-sk/frontend/idsk/all';
@import 'node_modules/@id-sk/frontend/idsk/all';
@import '@id-sk/frontend/govuk/all';
@import 'node_modules/@id-sk/frontend/govuk/all';
@import '../../includes/components/header-extended/podnikatel-header.scss';
@import '../../includes/components/pagination/paginations.scss';
@import './modal.scss';
@import './collapsibleFilter.scss';

//Fix vertically aligned radios spacing
.govuk-radios__item {
  margin-bottom: govuk-spacing(2);
}

//Fix inconsistency with example on IDSK page (without this focus box shadow is too big and it shouldn't even be here )
.govuk-input--error {
  &:focus {
    box-shadow: none;
  }
}

//Inline buttons
.govuk-button-group {
  margin-right: -15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;

  .govuk-button {
    margin-right: 15px;
  }
}

//Fix inline buttons break whole page
@media screen and (max-width: 640px) {
  .govuk-button-group {
    margin-right: 0 !important;
  }
}

//Hide open/close all accordion button
.govuk-accordion__controls {
  display: none;
}

.govuk-accordion__open-all {
  visibility: hidden !important;
}


//Custom container - original IDSK has width of 960px and that is not enough
.govuk-width-container {
  max-width: 1200px;
  margin-right: 15px;
  margin-left: 15px;
}

@media screen and (min-width: 1260px) {
  .govuk-width-container {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media screen and (max-width: 768px) {
  main.govuk-main-wrapper {
    padding-top: govuk-spacing(2);
  }
}

.block-link {
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: govuk-spacing(1);
  }
}

.td-no-border {
  border-bottom: none;
}

//Border which indicates separating from data in mobile table view
.mobile-table-border-bottom-3 {
  border-bottom: 3px solid #bfc1c3;
}

.td-no-wrap {
  white-space: nowrap;
}

.inline-link-wrapper {
  .govuk-details__text {
    display: flex;
    flex-direction: row;

    .inline-link {
      display: flex;
      align-items: center;
      margin-right: govuk-spacing(3);

      &:last-of-type {
        margin-right: none;
      }

      svg {
        margin-right: govuk-spacing(1);
      }
    }
  }
}

//inpage navigation
.idsk-in-page-navigation__title {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .idsk-in-page-navigation {
    width: 100%;
    margin-left: 0;
    margin-top: -10px;
    position: relative !important;

    .idsk-in-page-navigation__link-number {
      display: none !important;
    }

    .idsk-in-page-navigation-wrapper {
      .idsk-in-page-navigation__link-panel {
        .idsk-in-page-navigation__link-panel-button {
          padding-left: 15px;

          &::before {
            right: 24px;
          }
        }
      }

      .idsk-in-page-navigation__list {
        .idsk-in-page-navigation__list-item--active {
          &::before {
            top: 8px;
          }
        }
      }
    }
  }

  .idsk-in-page-navigation--sticky {
    top: 0;
  }
}

//Prevent scrolling body when mobile menu is open
.overflow-hidden {
  overflow: hidden;
}

//Change govukdetails component icon to custom chevron
.govuk-details__summary {
  padding-right: 25px;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: auto;
    position: absolute;
    background: url('../icons/chevron_bottom.svg') no-repeat left;
    border: none;
    border-width: unset;
    clip-path: unset;
    border: none !important;
  }
}

.govuk-details[open] > .govuk-details__summary:before {
  content: '';
  width: 100%;
  height: auto;
  position: absolute;
  background: url('../icons/chevron_top.svg') no-repeat left;
  border: none;
  border-width: unset;
  clip-path: unset;
}

.table--mobile {
   @include govuk-media-query($from: desktop) {
    display: none;
  }
}

.table--desktop {
  @include govuk-media-query($until: desktop) {
    display: none;
  }
}

.idsk-button--blue {
  background-color: govuk-colour("blue");
}

.idsk-header-web__main--login-action {
  display: flex !important;
  align-items: center;
}

.idsk-header__user-icon {
  width: 2rem !important;
  height: 2rem !important;
  margin-right: 0 !important;
}

.idsk-button-group--filters-gap {
  row-gap: 20px;
  column-gap: 10px;
}

.idsk-button-group--edit-gap {
  row-gap: 20px;
  column-gap: 30px;
}

.govuk-input--responsive-placeholder {
  @include govuk-media-query($until: tablet) {
    &::placeholder {
      color: transparent;
    }
    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent;
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: transparent;
    }
    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: transparent;
    }
    &::-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: transparent;
    }
  }
}

.idsk-footer-extended-inline-list--centered {
  display: flex;
  flex-direction: column;
  @include govuk-media-query($from: tablet) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.d-flex {
  display: flex;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-justify-end {
  justify-content: flex-end;
}

.govuk-link--link-back {
  svg {
    transform: rotate(90deg);
  }
}

.text-blue {
  color: govuk-colour("dark-blue");
}

.button-link {
  color: govuk-colour("blue");
  border: none;
  background: none;
  height: fit-content;
  cursor: pointer;
}

.idsk-custom-tag {
    display: inline-flex;
    font-weight: 700;
    font-family: Source Sans Pro, Arial, sans-serif !important;
    align-items: center;
    line-height: 1;
    padding: 5px 1px 5px 6px;
    color: govuk-colour("white");
    margin-right: 15px;
    margin-bottom: 10px;
    background-color: govuk-colour("light-blue");

    button {
      margin-left: 5px;
      padding: 0 5px;
      height: 14px;
      line-height: 1;
      border: none;
      background: none;
      cursor: pointer;
      &:focus {
        background-color: govuk-colour("yellow");
      }
    }
}

.idsk-custom-tag--lighter-grey {
  background-color: govuk-colour("light-grey");
  color: govuk-colour("black");
}

.text-align-right {
  text-align: right;
}

.table__inset-text {
  display: flex;
  margin-top: 20px;
  @include govuk-media-query($until: desktop) {
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
}

.show-desktop {
  @include govuk-media-query($until: desktop) {
    display: none;
  }
}

.list--border-gray {
  > li {
    border-bottom: 1px solid #bfc1c3;
  }
}

.list__vykaz {
  > li:first-child {
    border-top: 1px solid #bfc1c3;
  }
  > li:not(:last-child) {
    border-bottom: 1px solid #bfc1c3;
  }
}

.file-text {
  word-wrap: break-word;
}

.extended-search {
  select {
    width: 100%;
  }
}

.badge {
  padding: 2px 6px;
  background-color: govuk-colour("green");
  color: white;
}

.color-warning {
  background-color: govuk-colour("red");
}

.scroll {
  overflow-x: auto;
}

.text-gray {
  color: #626A6E;
}

.govuk-accordion__section-header--blue {
  border-top: 1px solid #0065b3 !important;
}

.govuk-accordion--blue {
  border-bottom: 1px solid #0065b3 !important;
}

.idsk-registration-for-event__form {
  .govuk-form-group {
    max-width: 600px !important;
  }
}

.w-max-450 {
  max-width: 450px;
}

.bg-gray {
  background-color: #F3F2F1;
}

input[type="file"].idsk-button_input::file-selector-button  {
    background-color: #f3f2f1;
    box-shadow: 0 2px 0 #929191;
    width: auto;
    line-height: 1;
    font-family: "Source Sans Pro", "Arial", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 1.1875rem;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    width: auto;
    margin: 0;
    margin-bottom: 2px;
    padding: 8px 10px 7px;
    border: 2px solid transparent;
    border-radius: 0;
    text-align: center;
    vertical-align: top;
    cursor: pointer;
    -webkit-appearance: none;
}

input[type="file"].idsk-button_input {
  min-width: 125px;
  width: 125px;
  overflow: visible;
  color: transparent;
}

input[type="file"].idsk-button_input:focus {
  outline: none;
  &::file-selector-button {
    background-color: #FFDF0F;
    box-shadow: 0 2px 0 #0B0C0C;
  }
}

input[type="file"].idsk-button_input:hover {
  &::file-selector-button {
      border: 2px solid #FFDF0F;
      background-color: #DEE0E2 !important;
  }
}
.govuk-file-upload--error {
  input[type="file"].idsk-button_input {
    margin-right: -5px;
  }
}

.file-upload__wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @include govuk-media-query($from: tablet) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
}

.file-upload--uploaded {
  > ul {
    width: 100%;
  }
  justify-content: unset;
  gap: 0 !important;
  flex-direction: column !important;
  align-items: start !important;
}