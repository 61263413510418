.collapsibleFilter {
  border-bottom: 1px solid govuk-colour('grey-2');
  .govuk-details__summary {
    padding-left: 0;
    padding-right: 25px;

    &::before {
      background-position: right;
    }
  }

  .govuk-details[open] > .govuk-details__summary:before {
    background-position: right;
  }

  .collapsibleFilter__title {
    color: govuk-colour('blue');
    text-decoration: underline;
  }

  .collapsibleFilter__content {
    display: none;
  }

  .podnikatel-filter-button-container {
    text-align: right;
  }

  .open {
    display: block !important;
  }
}

